.content-bulletpoints
{
    margin-top: 8px;
}

.content-bulletpoint-container
{
    display: table;

    margin-top: 15px;
    margin-bottom: 0px;

    width: 100%;

    vertical-align: middle;

    background-color: #ffffff;

    border-radius: 6px;
}

.content-bulletpoint-icon-info
{
    display: table-cell;
    
    width: 64px;
    height: 64px;
    
    background-image: url('/public/image/icon_green_info.svg');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-bulletpoint-icon-btw
{
    display: table-cell;
    
    width: 64px;
    height: 64px;
    
    background-image: url('/public/image/icon_green_btw.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-bulletpoint-icon-custom
{
    display: table-cell;
    
    width: 64px;
    height: 64px;
    
    background-image: url('/public/image/icon_green_custom.svg');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-bulletpoint-icon-fast
{
    display: table-cell;
    
    width: 64px;
    height: 64px;
    
    background-image: url('/public/image/icon_green_fast.svg');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-bulletpoint-text
{
    display: table-cell;
    padding-right: 8px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    vertical-align: middle;
}

.content-copyright
{
    margin-top: 12px;
    margin-bottom: 12px;

    color: #8a8a8a;
    
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    -webkit-font-smoothing: antialiased;
}

.content-copyright-link
{
    color: #8a8a8a;
}

.content-copyright-link:hover
{
    cursor: pointer;

    color: #8a8a8a;

    text-decoration: underline;
}

.content-crosssell-container
{
    margin-top: 64px;
    margin-bottom: 64px;
}

.content-crosssell-subcontainer
{
    display: flex;
    justify-content: space-between;

    margin-top: 24px;
    padding: 18px;

    width: 100%;
    height: 96px;
    
    color: #000000;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    
    border-radius: 6px;

    box-shadow: 0 10px 32px #0000000f;

    -webkit-transition: transform 0.15s linear;
    -ms-transition: transform 0.15s linear;
    transition: transform 0.15s linear;
}

.content-crosssell-subcontainer:hover
{
    cursor: pointer;

    transform: scale(1.075);
}

.content-crosssell-text
{
    display: inline-block;

    margin-top: 14px;

    color: #ffffff;

    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    text-align: left;
    -webkit-font-smoothing: antialiased;
}

.content-crosssell-chevron
{
    display: inline-block;
    
    margin-top: 14px;

    width: 32px;
    height: 32px;

    background-image: url('/public/image/icon_chevroncircle.svg');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-error-container
{
    margin-top: 24px;
    padding: 18px;
    
    color: #a32a2a;
    background-color: rgb(255, 219, 219);

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    
    border-radius: 6px;
}

.content-footer
{
    margin-top: 48px;
    margin-bottom: 48px;
    
    color: #636363;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;
}

.content-footer-icon
{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;

    width: 32px;
    height: 32px;

    background-image: url('/public/image/icon_idea.svg');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-info-container
{
    margin-top: 24px;
    padding: 18px;
    
    color: #2a6aa3;
    background-color: #dbeeff;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    
    border-radius: 6px;
}

.content-info-icon
{
    display: inline-block;
    
    margin-right: 5px;

    width: 18px;
    height: 18px;

    vertical-align: text-top;
    
    background-image: url('/public/image/icon_info.svg');
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-overview-container
{
    margin-bottom: 48px;
}

.content-overview-subcontainer
{
    padding: 18px;
    margin-top: 24px;
    margin-bottom: 16px;

    color: #000000;
    background-color: #ffffff;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    border-radius: 6px;

    box-shadow: 0 10px 32px #0000000f;
}

.content-overview-subcontainer-header
{
    margin-bottom: 28px;
}

.content-overview-subcontainer-footer
{
    margin-top: 16px;
    margin-bottom: 2px;
}

.content-overview-table
{
    width: 100%;
}

.content-overview-table-cell-left-top
{
    padding: 12px;
    padding-left: 18px;
    margin-bottom: 6px;
    
    color: #000000;
    background-color: #f3f3f3;

    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    border-top-left-radius: 8px;

    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-bottom-color: #ffffff;
}

.content-overview-table-cell-right-top
{
    padding: 12px;
    padding-left: 18px;
    margin-bottom: 6px;
    
    color: #000000;
    background-color: #f3f3f3;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    border-top-right-radius: 8px;

    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-bottom-color: #ffffff;
}

.content-overview-table-cell-left
{
    padding: 12px;
    padding-left: 18px;
    margin-bottom: 6px;
    
    color: #000000;
    background-color: #f3f3f3;

    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    border-top-style: solid;
    border-top-width: 4px;
    border-top-color: #ffffff;

    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-bottom-color: #ffffff;
}

.content-overview-table-cell-right
{
    padding: 12px;
    padding-left: 18px;
    margin-bottom: 6px;
    
    color: #000000;
    background-color: #f3f3f3;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    border-top-style: solid;
    border-top-width: 4px;
    border-top-color: #ffffff;

    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-bottom-color: #ffffff;
}

.content-overview-table-cell-left-bottom
{
    padding: 12px;
    padding-left: 18px;
    margin-bottom: 6px;
    
    color: #000000;
    background-color: #f3f3f3;

    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    border-bottom-left-radius: 8px;

    border-top-style: solid;
    border-top-width: 4px;
    border-top-color: #ffffff;
}

.content-overview-table-cell-right-bottom
{
    padding: 12px;
    padding-left: 18px;
    margin-bottom: 6px;
    
    color: #000000;
    background-color: #f3f3f3;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    border-bottom-right-radius: 8px;

    border-top-style: solid;
    border-top-width: 4px;
    border-top-color: #ffffff;
}

.content-overview-title
{
    margin-top: 4px;
    margin-bottom: 24px;

    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-overview-title-icon
{
    display: inline-block;
    
    margin-right: 8px;

    width: 28px;
    height: 28px;
    vertical-align: bottom;

    background-image: url('/public/image/icon_powerplug.svg');
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-overview-price
{
    margin-top: 8px;
    margin-bottom: 32px;
    padding: 16px;
    padding-top: 24px;
    padding-left: 24px;

    color: #96d248;
    background-color: #96d24821;

    border-radius: 8px;
}

.content-overview-price-title-mobile
{
    display: inline-block;

    font-size: 36px;
    line-height: 26px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

.content-overview-price-title-desktop
{
    display: inline-block;

    font-size: 48px;
    line-height: 26px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

.content-overview-price-text
{
    display: inline-block;

    margin-left: 8px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #96d248;
}

.content-overview-price-payback
{
    margin-top: 8px;
    margin-bottom: 4px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #96d248;
}

.content-overview-price-payback-icon
{
    display: inline-block;
    
    margin-right: 8px;

    width: 28px;
    height: 28px;
    vertical-align: bottom;

    background-image: url('/public/image/icon_hand.svg');
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position: center;
}

.content-overview-installq-logo
{
    margin-top: 32px;

    width: 100%;
    height: 64px;
    vertical-align: bottom;

    background-image: url('/public/image/logo_installq.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.content-overview-installq-text
{
    margin-top: 12px;

    text-align: center;
}

.content-paragraph
{
    margin-top: 24px;
    margin-bottom: 0px;
    padding-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-title-h1-mobile
{
    margin-top: 32px;

    font-size: 40px;
    line-height: 44px;
    font-weight: 700;

    color: #000000;
}

.content-title-h1-desktop
{
    margin-top: 32px;
    margin-bottom: 12px;

    font-size: 40px;
    line-height: 44px;
    font-weight: 700;

    color: #000000;
}